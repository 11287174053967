//
// Tabs
//

// Base
.nav-pills.nav-tabs-btn {
  @include kt-clearfix();
  display: flex;
  justify-content: center;

  .nav-item {
    max-width: 100%;
    min-width: 75px;
    margin: 0.5rem;
    box-shadow: rgba(69, 65, 78, 0.06) 0px 1px 15px 1px;
    border-radius: 0.5rem;
    text-align: center;

    .nav-link {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 0.85rem 0.75rem;

      .nav-link-icon {
        display: block;
        padding-bottom: 0.5rem;

        i {
          margin: 0;
          font-size: 1.3rem;
        }
      }

      .nav-link-title {
        display: block;
        font-weight: 500;
      }
    }
  }

  .nav-link {
    border: 0;
    padding: 12px 0;
    font-size: 1rem;
    font-weight: 400;

    @include kt-icons-style(
      font-size,
      (
        fontawesome: 1.2rem,
        lineawesome: 1.3rem,
        flaticon: 1.4rem,
      )
    );

    i {
      vertical-align: middle;
      line-height: 0;
      display: inline-block;
      margin-right: 0.5rem;
    }
  }

  &.nav-tabs-btn-2x {
    a.nav-link {
      border-bottom-width: 2px !important;
    }
  }

  &.nav-tabs-btn-left {
    float: left;
  }

  &.nav-tabs-btn-right {
    float: right;
  }

  // Portlet Tabs
  .kt-portlet--tabs & {
    margin: 0 0 -1px 0;

    &.nav-tabs-btn-2x {
      margin: 0 0 -2px 0;
    }

    .nav-item {
      display: flex;
      align-items: stretch;

      .nav-link {
        display: flex;
        align-items: center;
      }
    }
  }

  // Bold tab links
  &.nav-tabs-bold {
    .nav-item {
      .nav-link {
        font-weight: 500;
      }
    }
  }
}

// Skin
.nav-pills.nav-tabs-btn {
  a.nav-link {
    &.active {
      .nav-link-icon {
        i {
          color: #fff;
        }
      }

      .nav-link-title {
        color: #fff;
      }
    }

    .nav-link-icon {
      i {
        color: kt-base-color(label, 1);
      }
    }

    .nav-link-title {
      color: kt-base-color(label, 2);
    }
  }

  // state colors
  @each $name, $color in $kt-state-colors {
    &.nav-pills-btn-#{$name} {
      a.nav-link {
        &.active {
          background-color: kt-get($color, base);

          .nav-link-icon {
            i {
              color: kt-get($color, inverse) !important;
            }
          }

          .nav-link-title {
            color: kt-get($color, inverse) !important;
          }
        }
      }
    }
  }
}

// Mobile Mode
@include kt-mobile {
  .nav-pills.nav-tabs-btn {
    .nav {
      .nav-item {
        max-width: 100%;
        width: 6.5rem;
        margin: 0.6rem;

        .nav-link {
          .nav-link-icon {
            i {
              font-size: 1.2rem;
            }
          }

          .nav-link-title {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
