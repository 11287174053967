//
// Notes
//

.kt-notes {
  .kt-notes__items {
    margin: 0;
    padding: 0;

    .kt-notes__item {
      left: 8px;
      border-left: 2px solid kt-base-color(grey, 2);
      padding: 0 0 50px 45px;
      position: relative;
      margin: 0 5px 0 17px;

      .kt-notes__media {
        position: absolute;
        top: 0;
        left: -25.5px;

        img {
          max-width: 50px;
          max-height: 50px;

          @include kt-rounded {
            border-radius: 50%;
          }
        }

        .kt-notes__icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          background-color: #fff;
          border: 2px solid kt-base-color(grey, 2);

          @include kt-rounded {
            border-radius: 50%;
          }

          i {
            color: kt-base-color(label, 2);
            font-size: 1.8rem;
          }
        }

        .kt-notes__user {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          font-size: 1.2rem;
          background-color: #fff;
          border: 2px solid kt-base-color(grey, 2);
          color: kt-base-color(label, 2);

          @include kt-rounded {
            border-radius: 50%;
          }
        }

        .kt-notes__circle {
          width: 25px;
          height: 25px;
          display: block;
          border: 2px solid kt-base-color(grey, 2);
          margin-left: 12px;
          background-color: #fff;

          @include kt-rounded {
            border-radius: 50%;
          }
        }
      }

      .kt-notes__content {
        background-color: #f7f8fc;
        padding: 0.5rem 1rem 1rem 1.5rem;

        .kt-notes__section {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .kt-notes__info {
            display: flex;
            flex-flow: wrap;
            align-items: baseline;

            .kt-notes__title {
              font-size: 1.1rem;
              color: kt-base-color(label, 3);
              font-weight: 500;
              padding-right: 0.5rem;

              &:hover {
                color: kt-brand-color();
                transition: color 0.3s ease;
              }
            }

            .kt-notes__desc {
              color: kt-base-color(label, 2);
              font-weight: 400;
              padding-right: 1rem;
            }
          }

          .kt-notes__dropdown {
            .btn-icon {
              width: 26px;
              height: 26px;
            }
          }
        }

        .kt-notes__body {
          color: kt-base-color(label, 2);
          font-weight: 400;
          padding-right: 3rem;
          padding-top: 0.5rem;
          display: block;
        }

        @include kt-rounded {
          border-radius: $kt-border-radius;
        }

        &:after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          top: 10px;
          right: 100%;
          margin-right: -3.5rem;
          border-right: solid 10px #f7f8fc;
          border-bottom: solid 17px transparent;
          border-left: solid 17px transparent;
          border-top: solid 17px transparent;
        }
      }

      &:last-child {
        border-left: 0;
      }

      &.kt-notes__item--clean {
        .kt-notes__content {
          background-color: #fff;
          padding: 0;

          .kt-notes__info {
            //margin-top: -0.5rem;
          }

          &:after {
            display: none;
          }
        }
      }
    }

    .kt-notes__item:last-child {
      .kt-notes__media {
        .kt-notes__circle {
          margin-left: 14px;
        }
      }
    }
  }
}

@include kt-tablet-and-mobile {
  .kt-notes {
    .kt-notes__items {
      .kt-notes__item {
        margin: 0 7px 0 17px;

        .kt-notes__content {
          padding: 0.5rem 1rem 0.7rem 1rem;

          .kt-notes__desc {
            padding-top: 0.5rem;
            display: block;
          }

          &:after {
            margin-right: -3.8rem;
          }
        }
      }
    }
  }
}
