//
// Layout Config
//

// Layout
$kt-aside-default-width: 265px;
$kt-aside-minimize-width: 70px;
$kt-aside-offcanvas-width: 255px;

// Layout Skins
$kt-layout-skins: (
  dark: #1e1e2d,
  light: #ffffff,
);

// content bg color
$kt-content-bg-color: #f2f3f8;

// Border Radius
$kt-border-radius: 4px;

// Page padding
$kt-page-padding: (
  desktop: 25px,
  mobile: 15px,
);

// Page container width
$kt-page-container-width: 1380px;
