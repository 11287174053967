//
// TinyMCE
//

// Base
.tox-target {
  display: none;
}

.tox-tinymce {
  border-radius: $kt-border-radius !important;
  height: 500px !important;
}
